<template>
  <div v-if="schemelist[0].id!==''" class="schenme">
    <div class="line"></div>
    <!-- schenme-box 版心盒子 -->
    <div class="schenme-box">
      <!-- 方案页面头部 -->
      <div class="schenme-header">
        <div class="schenme-header-leftbox">
          <img src="@/assets/scheme/solution.png" alt="" />
        </div>
        <div class="schenme-header-rightbox">
          <a v-for="(v,i) in schemelist" :key="i" class="anchor-point" @click="toschemelist(i)">{{ i+1 }}</a>
        </div>
      </div>
      <!-- 解决方案总体盒子 -->
      <div v-for="(item, index) in schemelist" :key="index" class="schenme-content">
        <div :ref="'pinglun'+index" class="schenme-schenme1">
          <div class="schenme-schenme1-top">
            <div class="schenme-schenme1-top-left">
              <div class="schenme1-title">
                <div></div>
                <div class="short-vertical-line">
                  <div></div>
                </div>
                <div class="schenme1-title-num">
                  {{ index+1 }}
                </div>
              </div>
              <div class="schenme1-text hidden">
                <img class="zoomimg" :src="item.programId" alt="" />
              </div>
            </div>
            <!-- 方案一的右半部分 -->
            <div class="schenme-schenme1-top-right" @click="schemegodetail1(item.id)">
              <div class="title">{{ item.schemeName }}</div>
              <div v-if="item.customer" style="font-weight:bold">目标客户：{{ item.customer }}</div> <br/>
              <div class="cotent-text">{{ item.programIntr }}</div>
            </div>
          </div>
          <!-- <div class="schenme1boottomimg hidden">
            <img src="http://www.gangwantech.com/gangwanoa/userfiles/807eb1a606ae45b290e54acb048b51d5/files/biz.solution/solution/2020/09/mxcp_1600610941455_u%3D3329152082%2C3358158925%26fm%3D26%26gp%3D0.jpg" alt="" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetailList } from '@/api/scheme/scheme.js'
export default {
  data() {
    return {
      schemelist: [
        {
          id: '',
          schemeName: '',
          programIntr: '',
          programId: '',
          programImg: ''
        }
      ]
    }
  },
  created() {
    // 获取方案列表
    getDetailList()
      .then((res) => {
        this.schemelist = res.data.data
        // console.log(this.schemelist)
        for (let i = 0; i < this.schemelist.length; i++) {
          this.schemelist[i].programImg = this.schemelist[i].programImg.split(',')
        }
        // console.log(this.schemelist)
      })
  },
  methods: {
    // 去详情页
    // godetail() {
    //   this.$router.push({ path: '/detail', id: '1' })
    // },
    // 去当前锚点
    toschemelist(value) {
      console.log(this.$refs['pinglun' + value])
      this.$refs['pinglun' + value][0].scrollIntoView(true)
    },
    // 去详情页
    schemegodetail1(id) {
      // console.log(id)
      this.$router.push({path: 'detail', query: {id} })
    }
  }
}
</script>

<style lang="less" scoped>
.schenme {
  a{
    display: block;
    color: black;
  }
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Microsoft YaHei",Arial, sans-serif,"PingFang SC","微软雅黑";
  .line{
    height: 10px;
    width: 100%;
    margin-top: -10px;
    background-color: transparent;
    background-image: -webkit-linear-gradient(left, #5bd8ff, #ff0000);
    box-shadow: inset 0 -2px 2px rgb(0 0 0 / 40%);
    border-radius: 3px 0 0 3px;
   }
    //鼠标滑过放大
  .zoomimg:hover{
    // display: none;
    transform: scale(1.2);
    transition: all 1s linear;
  }
  .hidden{
    overflow: hidden;
  }
  .schenme-box{
    width: 100%;
    max-width: 1200px;
    min-width: 1200px;
    margin-top: 58px;
    .lang-vertical-line{
      height: 150px;
      width: 2px;
      background-color: #303030;
    }
  }
  .schenme-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .schenme-header-leftbox {
    width: 390px;
    height: 90px;
    // background-color: red;
  }
  .schenme-header-rightbox{
    display: flex;
     //锚点按钮按钮
     a{
       cursor: pointer;
       text-decoration: none;
     }
    .anchor-point {
      margin-left: 16px;
      width: 40px;
      height: 40px;
      border: 1px solid #FE2704;
      border-radius: 50%;
      margin-left: 16px;
      text-align: center;
      line-height: 40px;
      font-weight: 500;
      font-size: 25px;
      color:#FE2704;
    }
    .anchor-point:hover{
      border: 1px solid #3688E4;
      color:#3688E4;
    }
  }
  }
  .schenme-content{
    width: 100%;
    // background-color: cadetblue;
    .schenme-schenme1-top{
        display: flex;
        .schenme-schenme1-top-left{
          width: 244px;
          height: 428px;
          margin-left: 170px;
          // background-color: skyblue;
          .schenme1-title{
            height: 178px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .short-vertical-line{
              display: flex;
              justify-content: center;
              div{
                height: 90px;
                width: 2px;
                background-color: #303030;
                margin: 60px 0 30px 0;
              }
            }
            .schenme1-text:hover{
              transform: scale(1);
              transition: all 1s linear;
              transition-property: all;
              transition-duration: 1s;
              transition-timing-function: linear;
              transition-delay: 0s;
            }
            div{
              width: 60px;
              text-align: center;
            }
            .schenme1-title-num{
              font-size: 60px;
              color: #000;
              font-style: italic;
              text-align:right
            }
          }
          .schenme1-text{
              width: 240px;
              height: 240px;
                >img{
                  width: 240px;
                  height: 240px;
                }
          }
        }
        .schenme-schenme1-top-right{
          width: 715px;
          margin-left: 64px;
          cursor: pointer;
            .title{
              font-size: 30px;color: #000;
              font-style: italic;
              margin: 80px 0 24px;
              }
            .text{
              box-sizing: border-box;
            }
        }
      }
      .schenme-schenme1-bottom{
        margin-left: 170px;
        display: flex;
        height: 520px;
        justify-content: space-between;
        // background-color: cadetblue;
        .schenme-schenme1-bottom-left{
          flex:0 0 auto;
          width: 240px;
          height: 520px;
          // background-color: red;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #FE2704;
          font-size: 30px;
        }
        .schenme-schenme1-bottom-right{
          width: 1270px;
          height: 520px;
          margin-left: 68px;
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          .bottom-right-imgbox{
            margin-right: 30px;
          }
        }
      }
      .schenme1boottomimg{
        padding-left: 180px;
      }
    .schenme-schenme2{
      .schenme2-title{
        span:nth-of-type(1){
          font-size: 60px;
          color: #000;
          font-style: italic;
          display: inline-block;
          padding-bottom: 5px;
          border-bottom: 2px solid #ccc;
        }
        span:nth-of-type(2){
          font-size: 30px;
          color: #000;
          font-style: italic;
          display: inline-block;
          margin-left: 20px;
        }
      }
    }
    .schenme-schenme4{
      padding-left: 180px;
      .schenme4-top{
        display: flex;
        .titleimgbox{
          width: 240px;
          height: 240px;
          // background-color: red;
          img{
            width: 240px;
            height: 240px;
          }
        }
        .title-text{
          padding-left: 50px;
          span:nth-of-type(1){
            font-size: 60px;
            color: #000;
            font-style: italic;
            display: inline-block;
            padding-bottom: 5px;
            border-bottom: 2px solid #ccc;
          }
          span:nth-of-type(2){
            font-size: 30px;
            color: #000;
            font-style: italic;
            display: inline-block;
            margin-left: 20px;
          }
        }
      }
      .schenme4-bottom{
        display: flex;
      }
    }
    .schenme-schenme6{
      .schenme6-top{
        margin-left: 170px;
        display: flex;
        .schenme6-imgbox{
          width: 240px;
          height: 240px;
        }
        .schenme6-contentbox{
          padding-left: 50px;
          .title{
            span:nth-of-type(1){
            font-size: 60px;
            color: #000;
            font-style: italic;
            display: inline-block;
            padding-bottom: 5px;
            border-bottom: 2px solid #ccc;
            }
            span:nth-of-type(2){
              font-size: 30px;
              color: #000;
              font-style: italic;
              display: inline-block;
              margin-left: 20px;
            }
            div{
              width: 546px;
              padding-left: 80px;
            }
          }
        }
      }
      .schenme6-bottom{
        margin: 26px 11px 20px 180px;
        width: auto;
        height: 100%;
      }
    }
    .schenme-schenme8{
      .schenme6-top{
        margin-left: 170px;
        display: flex;
        .schenme6-imgbox{
          width: 240px;
          height: 240px;
        }
        .schenme6-contentbox{
          padding-left: 50px;
          .title{
            span:nth-of-type(1){
            font-size: 60px;
            color: #000;
            font-style: italic;
            display: inline-block;
            padding-bottom: 5px;
            border-bottom: 2px solid #ccc;
            }
            span:nth-of-type(2){
              font-size: 30px;
              color: #000;
              font-style: italic;
              display: inline-block;
              margin-left: 20px;
            }
            div{
              width: 546px;
              padding-left: 80px;
            }
          }
        }
      }
    }
    .footer{
      display: flex;
      margin: 26px 0 0 170px ;
    }
  }
}
</style>
